<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 m8 offset-m2 l6 offset-l3">
            <div class="card col s12 vert-offset-top-1 padding-top-half">

                <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">
                    <router-link
                            class="btn-small homeboy-charcoal-black white-text margin-right-half"
                            to="/send/otp"
                    >
                            <i class="material-icons">arrow_back</i>
                        </router-link>
                    <span>
                        Verify your mobile number
                    </span>
                </div>
                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">
                    <div class="row">
                        <span class="font-12 homeboy-charcoal-black-text">OTP has expired? If yes <router-link class="underline homeboy-gold-text" to="/send/otp">click here to send a new one</router-link></span>
                    </div>
                    <div class="row">
                        <form>
                            <div class="row vert-offset-bottom-half">

                                <div class="col s12 no-padding">
                                    <div class="row col s12 m5 vert-offset-top-1">
                                        <custom-select-component ref="country" v-model="selected_country" :options="countries"  title="Type to select your Country"></custom-select-component>
                                    </div>
                                    <div class="col s12 m7 vert-offset-bottom-half">
                                        <input-component id="mobile" type="tel" v-model="mobile" label="Mobile Number" required="required" minlength="6"></input-component>
                                    </div>

                                </div>

                            </div>
                            <div class="row vert-offset-bottom-half">

                                <div class="col s12">
                                    <input-component  id="otp" type="password" v-model="otp" label="One Time Pin" required="required" helper="The One Time Pin is a pin sent to your mobile number." minlength="6"></input-component>
                                </div>
                            </div>

                            <!--<input type="text" hidden id="customer" value=""/>-->

                            <div class="row vert-offset-bottom-half">
                                <div class="col s12 m6 offset-m3">
                                    <a @click.prevent="confirmVerification" class="btn btn-block homeboy-gold-text homeboy-charcoal-black  font-12">
                                        Verify
                                    </a>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../../http-common"
    import { bus } from "../../main"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import {mapState} from "vuex"
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue"



    export default {
        name: 'VerifyMobile',
        components:{
            InputComponent,CustomSelectComponent
        },
        data(){
            return{
                otp:"",
                mobile:"",
                selected_country:""
            }
        },
        created(){
            console.log("LOGGING HERE");
            this.getCountries();

            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                if(_data == "confirmVerification")
                {
                    console.log("Trigger Save Pin reset");
                    this.mobileVerification();
                }
                this.$store.dispatch('SET_ACTION', '');
            });
            //this.confirmPinChange();
        },
        mounted(){
            //this.$refs.country.initializeSelect();
            this.selected_country ="BW";


        },
        computed:{
            ...mapState({
                countries:state=>state.loyaltolocal.l2l_countries
            })
        },
        methods:{
            confirmVerification: function()
            {
                if(this.validateForm())
                {
                    this.$store.dispatch("SET_ACTION", "confirmVerification");
                }
            },
            validateForm: function()
            {
                let _arrErrors = new Array();
                if(typeof this.otp === "undefined" || this.otp === null || this.otp.length < 6)
                {
                    _arrErrors.push("Invalid OTP");
                }
                else if(this.otp.length > 12)
                {
                    _arrErrors.push("Invalid OTP");
                }

                if(typeof this.mobile === "undefined" || this.mobile === null || this.mobile.length <= 0)
                {
                    _arrErrors.push("Pleasse provide a valid Mobile Number");
                }
                else if(this.mobile.length > 12)
                {
                    _arrErrors.push("the provided Mobile number is too long");
                }
                else if(!this.mobile.replace("+", "").isNumeric())
                {
                    _arrErrors.push("Please provide a valid Mobile Number");
                }


                if(_arrErrors.length > 0)
                {
                    this.$store.dispatch('GET_MESSAGES',_arrErrors);
                    return false;
                }

                return true;
            },
            mobileVerification:function(){
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {

                    http({
                        url: '/customers/confirm/mobile',
                        data:{
                            key:this.otp,
                            msisdn:this.mobile,
                            country:this.selected_country,
                            source: this.$source

                        },
                        method: 'POST',
                        timeout: 30000
                    }).then(resp => {

                        this.$store.dispatch('STOP_PROCESSING');
                        //this.customer_services = resp.data.data;
                        //console.log("DATA : "+resp.data.data);

                        this.selected_country ="BW";
                        this.otp = "";
                        this.mobile = "";
                        this.$store.dispatch("GET_MESSAGES", ["Mobile phone successfully verified"]);

                        this.$router.push("/login");

                        resolve(resp)
                    }).catch((response) =>
                    {
                        console.log("ErrResponse: "+JSON.stringify(response.data));
                        this.$store.dispatch('STOP_PROCESSING');
                        this.$store.dispatch('GET_MESSAGES',response.data.messages);
                        //reject(err)
                    })
                })
            },

            getCountries:function () {
                this.$store.dispatch("GET_L2L_COUNTRIES_REQUEST");
            }
        }
    }
</script>
